<template>
	<div class="app-container">
		<el-header class="titles">区域管理</el-header>
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>条件查询</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button id="child" @click="searchTable()" type="primary" size="small" icon="el-icon-search">搜索</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="searchForm" ref="searchForm" size="small" label-width="100px">
					<el-form-item label="区域名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入区域名称"></el-input>
					</el-form-item>
					<el-form-item label="省份/地区：" prop="provinceName">
					  <el-select v-model="searchForm.provinceName" value-key="id" placeholder="请选择省份/地区" @change="changeoptionsTop()">
						  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="城市：" prop="cityName">
					  <el-select v-model="searchForm.cityName" value-key="id" placeholder="请选择城市" @change="changeoptions1Top()">
						  <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
					<el-form-item label="区/县：" prop="countyName">
					  <el-select v-model="searchForm.countyName" value-key="id" placeholder="请选择区/县">
						  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item"></el-option>
					  </el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button>
			<el-button type="danger" size="medium" icon="el-icon-delete" @click="allDle()">批量删除</el-button>
			<el-button type="success" size="medium" icon="el-icon-folder-add" @click="importConfirm">导入</el-button>
			<el-button type="success" size="medium" class="linkBtn"><el-link icon="el-icon-download" :underline="false" href="/lyh/区域导入.xlsx">导入模板下载</el-link></el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="areaList" @change="checkedChange">
				    <el-checkbox v-for="item in areaArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button slot="reference" type="primary" size="medium" icon="el-icon-s-grid" circle></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList(1)"></el-button>
			
			<el-upload class="upload-demo" ref="upload" :show-file-list="false" style="height: 0;"
				name="file"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
				:action="actionUrl" :on-success="uploadFun">
			</el-upload>
		</el-row>
		<el-card>
			<el-table v-loading="listLoading" :header-cell-style="{background:'#eef1f6',color:'#606266'}" :data="tableData" :stripe="true" border style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column fixed type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in areaArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110"></el-table-column>
				<el-table-column fixed="right" label="操作" min-width="180">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="editTable(scope.$index, scope.row)">修改</el-button>
						<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteTable(scope.$index, scope.row)">删除</el-button>
					</template>
				 </el-table-column>
			</el-table>
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="35%">
			<el-form :model="form" :rules="rules" ref="ruleForm">
			    <el-form-item label="省份/地区：" prop="region" :label-width="formLabelWidth">
			      <el-select v-model="form.region" value-key="id" placeholder="请选择省份/地区" @change="changeoptions()">
					  <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
			      </el-select>
			    </el-form-item>
				<el-form-item label="城市：" prop="region1" :label-width="formLabelWidth">
				  <el-select v-model="form.region1" value-key="id" placeholder="请选择城市" @change="changeoptions1()">
					  <el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="区/县：" prop="region2" :label-width="formLabelWidth">
				  <el-select v-model="form.region2" value-key="id" placeholder="请选择区/县" @change="changeoptions2()">
					  <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item"></el-option>
				  </el-select>
				</el-form-item>
				<el-form-item label="区域：" prop="name" :label-width="formLabelWidth">
					<el-input v-model="form.name" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button @click="cancel()">取 消</el-button>
			  <el-button type="primary" @click="dialogConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
		  return {
			  page: 1,
			  visible: false,
			  total: 0,//分页总条数
			  dialogTitle: '新增数据',//弹窗标题
			  dialogFormVisible: false,//修改、新增弹窗
			  formLabelWidth: '120px',//修改、新增弹窗-label宽度
			  tableData: [],//表格
			  multipleSelection: [],//表格选中数组
			  searchForm: {
			  	name: '',
				provinceName: '',
				cityName: '',
				countyName: ''
			  },//条件查询form
			  form: {
				  id: '',
				  name: '',
				  provinceId: '',
				  region: '',
				  cityId: '',
				  region1: '',
				  countyId: '',
				  region2: ''
			  },//修改、新增弹窗-表单内容
			  rules: {
			  	name: [{ required: true, message: '请输入区域名称', trigger: 'blur' }],
			  	region: [{ required: true, message: '请选择省份/地区', trigger: 'change' }],
			  	region1: [{ required: true, message: '请选择城市', trigger: 'change' }],
			  	region2: [{ required: true, message: '请选择区/县', trigger: 'change' }]
			  },
			  areaArr: [
				  {label: "区域", val: "name", dis: true},{label: "省-名称", val: "provinceName", dis: true},
				  {label: "市-名称", val: "cityName", dis: true},{label: "区-名称", val: "countyName", dis: true}
			  ],
			  areaList: ["name", "provinceName", "cityName", "countyName"],
			  options: [],
			  options1: [],
			  options2: [],//下拉框数据
			  actionUrl: '/piaodu/fa/system/import'
		  }
		},
		created() {
			if(process.env.NODE_ENV != 'production') {
				this.actionUrl = '/fa/system/import'
			} else {
				this.actionUrl = '/piaodu/fa/system/import'
			}
			this.getList(1)
			this.getOptions(-1, 'options')
		},
		methods: {
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList(1);
			},
			//获取表格数据
			getList(page, val) {
				this.listLoading = true;
				// val = val ? val : "";
				// 查询内容转数组
				let obj = this.searchForm;
				let arr = Object.keys(obj);
				let arrs = [];
				arr.forEach(item => {
					let o = {};
					o.col = item;
					o.val = obj[item].name ? obj[item].name : '';
					if(item == "name") {
						o.type = "like";
						o.val = obj[item];
					} else if(item == "stageId") {
						o.type = "=S";
					} else {
						o.type = "=";
					}
					arrs.push(o);
				})
				let data = {
					entity: 'FaExamArea',
					page: page,
					pageSize: 10,
					filter: JSON.stringify(arrs)
					// filter: '[{"col":"name","type":"like","val":"' + val + '"}]'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					console.log(res)
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			//获取下拉框数据
			getOptions(code,options) {
				let data = {
					code: code
				}
				this.$comjs.ajax.getAjax('/address/info/getAddress', data, this, res => {
					console.log(res)
					this[options] = res.list
				})
			},
			//搜索
			searchTable() {
				let val = this.searchForm.name
				this.page = 1;
				this.getList(this.page, val)
			},
			// 分页
			handleCurrentChange(val) {
				console.log(val)
				this.page = val
				this.getList(val)
			},
			// 导入-确定
			importConfirm(formName) {
				// 触发upload组件内部点击事件，弹出文件选择框
				this.$refs['upload'].$refs['upload-inner'].handleClick();
			},
			// 导入文件上传后
			uploadFun(response, file, fileList) {
				if(response.status == '-1') {
					this.$message({
						message: response.message,
						type: 'error'
					});
				} else if(response.status == 200) {
					this.importDialog = false;
					this.$message({
						message: response.message,
						type: 'success'
					});
					this.getList(this.page);
				}
			},
			//新增
			append(){
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.options1 = []
				this.options2 = []
				this.dialogTitle = "新增数据"
				this.dialogFormVisible = true
				this.form = {
					id: '',
					name: '',
					provinceId: '',
					region: '',
					cityId: '',
					region1: '',
					countyId: '',
					region2: ''
				}
			},
			//修改
			editTable(index,row) {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}
				this.dialogFormVisible = true
				this.dialogTitle = "修改数据"
				let data = {
					entity: 'FaExamArea',
					id: row.id,
				}
				this.$comjs.ajax.getAjax('/jqGrid/commonFindDetailById', data, this, res => {
					console.log(res)
					this.form = {
						id: row.id,
						name: res.data.name,
						provinceId: res.data.provinceId,
						region: res.data.provinceName,
						cityId: res.data.cityId,
						region1: res.data.cityName,
						countyId: res.data.countyId,
						region2: res.data.countyName
					}
					this.getOptions(res.data.provinceId, 'options1')
					this.getOptions(res.data.cityId, 'options2')
				})
			},
			//删除
			deleteTable(index,row) {
				let data = {
					entity: 'FaExamArea',
					id: row.id,
				}
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					console.log(res)
					console.log(row.id)
					this.tableData.splice(index, 1)
				});
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
				console.log(this.multipleSelection)
			},
			//批量删除
			allDle() {
				if(this.multipleSelection.length > 0) {
					let comments = this.multipleSelection
					let num = ""
					for (let i = 0;i<comments.length;i++){
						num = num + comments[i].id + ","
					}
					num = num.substr(0, num.length - 1)
					console.log(num)
					let data = {
						entity: 'FaExamArea',
						ids: num,
					}
					this.$confirm('是否删除', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$comjs.ajax.postAjax('/jqGrid/batchDelete', data, this, res => {
							this.$message({
								message: '删除成功！',
								type: 'success',
								duration: 1000
							});
						});
						for (let j = 0;j<comments.length;j++){
							this.tableData.splice(comments[j],1)
						}
					})
				}
				else {
					this.$message({
						message: '请选择要删除的内容！',
						type: 'error',
						duration: 1000
					});
				}
			},
			//新增/修改-确定
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(this.dialogTitle === '新增数据') {
							var data = {
								// id: this.form.id ? this.form.id : "",
								name: this.form.name,
								provinceId: this.form.region.code,
								provinceName: this.form.region.name,
								cityId: this.form.region1.code,
								cityName: this.form.region1.name,
								countyId: this.form.region2.code,
								countyName: this.form.region2.name
							}
						} else {
							var data = {
								id: this.form.id,
								name: this.form.name,
								provinceId: this.form.provinceId,
								provinceName: this.form.region,
								cityId: this.form.cityId,
								cityName: this.form.region1,
								countyId: this.form.countyId,
								countyName: this.form.region2
							}
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/system/editArea', data, this, res => {
								this.dialogFormVisible = false
								this.$refs[formName].resetFields()
								this.$message({
									message: '保存成功! ',
									type: 'success',
									duration: 1000
								});
								this.getList(this.page, this.searchForm.name);
								this.options1 = [];
								this.options2 = [];
							})
						})
					}
				})
			},
			//form表单取消
			cancel(){
				this.dialogFormVisible = false
			},
			//下拉框值改变
			changeoptions() {
				this.getOptions(this.form.region.code,'options1')
				this.form.provinceId = this.form.region.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region = this.form.region.name
				}
				this.form.region1 = ''
				this.form.region2 = ''
				// let data = {
				// 	code: this.form.region.code
				// }
				// this.$comjs.ajax.getAjax('/address/info/getAddress', data, this, res => {
				// 	console.log(res)
				// 	this.options1 = res.list
				// 	this.form.region1 = ''
				// 	this.form.region2 = ''
				// })
			},
			changeoptions1() {
				this.getOptions(this.form.region1.code,'options2')
				this.form.cityId = this.form.region1.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region1 = this.form.region1.name
				}
				this.form.region2 = ''
				// let data = {
				// 	code: this.form.region1.code
				// }
				// this.$comjs.ajax.getAjax('/address/info/getAddress', data, this, res => {
				// 	console.log(res)
				// 	this.options2 = res.list
				// 	this.form.region2 = ''
				// })
			},
			changeoptions2() {
				this.form.countyId = this.form.region2.code
				if(this.dialogTitle === '新增数据') {
				} else {
					this.form.region2 = this.form.region2.name
				}
			},
			changeoptionsTop() {
				this.getOptions(this.searchForm.provinceName.code,'options1')
				this.searchForm.cityName = ''
				this.searchForm.countyName = ''
			},
			changeoptions1Top() {
				this.getOptions(this.searchForm.cityName.code,'options2')
				this.searchForm.countyName = ''
			},
			// 多选框
			checkedChange(value) {
				console.log(value)
				let arr = this.areaArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.areaArr = arr;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
		position: relative;
	}
	.el-row {
		margin: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	#child {
		float: right;
		// position: absolute;
		// height: 36px;
		// width: 80px;
		// margin-top:-18px;
		// top: 50%;
		// left: 88%;
	}
	.linkBtn {
		padding: 0;
	}
	.linkBtn .el-link {
		display: block;
		padding: 10px 20px;
		color: #fff;
	}
	.linkBtn .el-link:hover {
		color: #fff;
	}
</style>
